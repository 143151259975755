import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Title, Text, Span } from "../../components/Core";
import imgPhoto from "../../assets/image/jpg/fer.jpg";
import imgSignLight from "../../assets/image/png/signature.png";

const LinkSocial = styled.a`
  color: ${({ theme }) => theme.colors.light} !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 1.63px;
  transition: 0.4s;
  &:hover {
    color: ${({ theme }) => theme.colors.light} !important;
    transform: translateY(-5px);
  }
`;

const About = ({ hero = true, bg = "dark", ...rest }) => {
  return (
    <>
      <Section hero={hero} bg={bg} {...rest}>
        <Container>
          <Row className="align-items-center">
            <Col lg="6">
              <div>
                <img src={imgPhoto} alt="folio" className="img-fluid" />
              </div>
            </Col>
            <Col lg="6">
              <div className="pl-lg-4 pt-5 pt-lg-0">
                <Title color="light" variant="secSm">
                  Fernanda Cruz Quintero
                </Title>
                <Text
                  color="light"
                  className="mt-3 mt-lg-5"
                  css={`
                    line-height: 1.5;
                  `}
                >
                Soy egresada de la licenciatura en Ingeniería en
                Producción Multimedia, generación 2015.
                </Text>
                <Text color="light" className="mt-4">
                Me desempeño como desarrolladora full-stack
                </Text>
                <div className="mt-4">
                  <Text color="light">Mandame coreo a</Text>

                  <Text variant="p">
                    <a
                      href="mailto:acquaentertainment@gmail.com"
                      className="font-weight-bold"
                    >
                      <Span color="light">acquaentertainment@gmail.com</Span>
                    </a>
                  </Text>
                </div>
                <Text color="light" className="mt-3 font-weight-bold">
                  Fernanda Cruz
                </Text>
                <Text color="light" variant="small">
                  Co-Fundadora de HostedRed
                </Text>
                <div className="mt-5 d-flex">
                  <LinkSocial
                    href="https://github.com/AcquaWh"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" mr-3"
                  >
                    Github
                  </LinkSocial>
                  <LinkSocial
                    href="https://www.linkedin.com/in/acquawh/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3"
                  >
                    Linkedin
                  </LinkSocial>
                  <LinkSocial
                    href="https://www.instagram.com/acquawh/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-weight-bold"
                  >
                    Instagram
                  </LinkSocial>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default About;
