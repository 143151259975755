export const menuItems = [
  {
    name: "paginas",
    label: "paginas",
    items: [
      {
        name: "angular",
        label: "angular",
        items: [
          { name: "erus", label: "erus" },
          { name: "kite", label: "kite" },
          { name: "erus-certificaciones", label: "erus certificaciones" },
          { name: "wingman", label: "wingman" },
        ],
      },
      {
        name: "laravel",
        label: "laravel",
        items: [
          { name: "alumni", label: "alumni" },
          { name: "eventos-cov", label: "eventos cov" },
        ],
      },
    ],
  },
];
