// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-alumni-js": () => import("./../../../src/pages/alumni.js" /* webpackChunkName: "component---src-pages-alumni-js" */),
  "component---src-pages-caffenio-js": () => import("./../../../src/pages/caffenio.js" /* webpackChunkName: "component---src-pages-caffenio-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-erus-certificaciones-js": () => import("./../../../src/pages/erus-certificaciones.js" /* webpackChunkName: "component---src-pages-erus-certificaciones-js" */),
  "component---src-pages-erus-js": () => import("./../../../src/pages/erus.js" /* webpackChunkName: "component---src-pages-erus-js" */),
  "component---src-pages-eventos-cov-js": () => import("./../../../src/pages/eventos-cov.js" /* webpackChunkName: "component---src-pages-eventos-cov-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kite-js": () => import("./../../../src/pages/kite.js" /* webpackChunkName: "component---src-pages-kite-js" */),
  "component---src-pages-nasa-spaceapps-js": () => import("./../../../src/pages/nasa-spaceapps.js" /* webpackChunkName: "component---src-pages-nasa-spaceapps-js" */),
  "component---src-pages-novutek-ui-js": () => import("./../../../src/pages/novutek-ui.js" /* webpackChunkName: "component---src-pages-novutek-ui-js" */),
  "component---src-pages-wingman-js": () => import("./../../../src/pages/wingman.js" /* webpackChunkName: "component---src-pages-wingman-js" */)
}

